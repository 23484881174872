<template>
  <div class="menu">
    <ul class="menu__list" v-if="!isProcessing">
      <div class="menu__list__item--inline" v-if="uid" @click="$router.push({ name: 'profile', params: { uname: uname } })">
        <v-img class="menu__list__item__icon" :src="user ? user.icon : ''" />
        <p class="menu__list__item__info" @click="$router.push({ name: 'profile', params: { uname: uname } })">
          {{ user ? user.name : '名無しさん' }}
        </p>
        <v-btn class="menu__list__item__btn" target="_blank" @click="$router.push({ name: 'profile', params: { uname: uname } })" outlined>プロフィールへ</v-btn>
      </div>
      <li class="menu__list__item">
        <router-link class="menu__list__item__text" :to="{ name: 'profile_edit_info' }">プロフィールの編集へ</router-link>
        <router-link class="menu__list__item__text" :to="{ name: 'profile_edit_link' }">リンクの編集へ</router-link>
      </li>
      <li class="menu__list__item">
        <router-link class="menu__list__item__text" :to="{ name: 'friends' }">フォロー中のユーザー</router-link>
      </li>
      <li class="menu__list__item">
        <a class="menu__list__item__text" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScb8wUtyq6igI_kUocsHn8HoSAeSNEM4iintJM7azlJ1Rq9ug/viewform">お問い合わせ</a>
      </li>
      <li class="menu__list__item">
        <h2 class="menu__list__item__title">本サービスについて</h2>
        <a class="menu__list__item__text" target="_blank" href="https://docs.google.com/document/d/1QJfAasQZvv04Ymf7sk544DZ3IgTMVQbBoN48y5gMDOI/edit?usp=sharing">利用規約</a>
        <a class="menu__list__item__text" target="_blank" href="https://docs.google.com/document/d/1OeFs1jzojWV-EFtcRmC54NtRioAiPvEbBxwlCE17cuQ/edit?usp=sharing">プライバシーポリシー</a>
      </li>
      <li class="menu__list__item">
        <h2 class="menu__list__item__title">運営会社</h2>
        <a class="menu__list__item__text" href="https://nukumo.link" target="_blank">一般社団法人nukumo</a>
      </li>
      <li class="menu__list__item">
        <p class="menu__list__item__text" @click="signout()">ログアウト</p>
      </li>
      <li class="menu__list__item">
        <router-link class="menu__list__item__text" :to="{ name: 'cancel' }">退会</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {String} ユーザー名
     */
    uname () {
      return this.$store.getters['uidUname/uname'](this.uid) || this.uid
    },
    /**
     * @return {Object} ユーザー
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    }
  },
  methods: {
    /**
     * サインアウト処理
     */
    async signout () {
      this.$store.commit('setSubmitting', true)

      const result = await this.$store.dispatch('auth/signout')
      if (result.status === 'error') {
        this.$store.commit('setTelop', { show: true, msg: 'ログアウトができませんでした。時間をおいて再度試してみてください。', type: 'error' })
        this.$store.commit('setSubmitting', false)
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.menu {
  box-sizing: border-box;
  width: 100vw;
  max-width: $max_width;
  margin: $header_height auto $footer_height auto;
  background-color: white;
  &__list {
    padding: 0;
    margin: 0;
    &__item {
      width: 100%;
      padding: $padding_height $padding_width;
      list-style: none;
      border-bottom: 1px solid $gray_color;
      &:last-child {
        border-bottom: none;
      }
      &--inline {
        @extend .menu__list__item;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__icon {
        flex: initial;
        width: 45px;
        height: 45px;
        background-color: $light_gray_color;
        border-radius: 50%;
      }
      &__info {
        flex: 1;
        margin: 0 0 0 10px;
        font-size: 1.6rem;
      }
      &__btn {
        margin-left: 10px;
        font-size: 1.2rem;
        color: $orange_color !important;
        text-align: center;
        border: 1px solid $orange_color;
        border-radius: 15px;
        &.v-btn:not(.v-btn--round).v-size--default {
          height: auto;
          padding: $unit_size $unit_size*2;
        }
      }
      &__title {
        margin: 0 0 10px;
        font-size: 1.2rem;
        line-height: 1.7rem;
        color: $orange_color;
      }
      &__text {
        display: block;
        margin: 0 0 10px 15px;
        font-size: 1.4rem;
        line-height: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
